import { BrowserRouter, Routes, Route } from "react-router-dom";
import PortfolioLanding from "./pages/PortfolioLanding";

import ReactGA from 'react-ga';
import { useEffect } from "react";
ReactGA.initialize('UA-147440668-01');

export default function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  return (
    <>
      <BrowserRouter basename={"/"}>
        <Routes>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            element={<PortfolioLanding />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}
